<template>
  <base-info-card title="Username"
      subtitle="Datos Fiscales"
      >
              <div class="contacto">
                  <v-form
                        ref="form"
                        >
                        <v-text-field
                        v-model="localForm.name"
                        label="Nombre Fiscal"
                        :rules="[v => !!v || 'Item is required']"
                        ></v-text-field>

                        <v-text-field
                        v-model="localForm.rfc"
                        label="RFC"
                        :rules="[v => !!v || 'Item is required']"
                        ></v-text-field>
                        
                        <v-text-field
                        v-model="localForm.address"
                        label="Calle y Numero"
                        :rules="[v => !!v || 'Item is required']"
                        ></v-text-field>

                        <v-text-field
                        v-model="localForm.address2"
                        label="Colonia"
                        :rules="[v => !!v || 'Item is required']"
                        ></v-text-field>

                        <v-text-field
                        v-model="localForm.city"
                        label="Ciudad"
                        :rules="[v => !!v || 'Item is required']"
                        ></v-text-field>

                        <v-text-field
                        v-model="localForm.state"
                        label="Estado"
                        :rules="[v => !!v || 'Item is required']"
                        ></v-text-field>

                        <v-text-field
                        v-model="localForm.country"
                        label="Pais"
                        :rules="[v => !!v || 'Item is required']"
                        ></v-text-field>

                        <v-text-field
                        v-model="localForm.zip"
                        label="CP"
                        :rules="[v => !!v || 'Item is required']"
                        ></v-text-field>

                        
                    <v-card-actions>
                        <v-row>
                        <v-col>
                        <base-btn class="success" @click="saveContact">Save</base-btn>
                        </v-col>
                        <v-col>
                        <base-btn color="error" @click="$emit('closeit')">Cancel</base-btn>
                        </v-col>
                        </v-row>
                    </v-card-actions>
                  </v-form>
              </div>
      </base-info-card>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props:{
        form:{
            Type: Object,
            default:{}
        }
    },
    data:()=>({
        localForm:{

        },        
        countries:[],
        states:[],
        cities:[],
    }),
    created(){
//        this.loadCountries();
    },
    mounted(){
        this.localForm = Object.assign({},this.form);
    },
    methods:{
        ...mapActions(['getCountries','getStates','getCities','addContact','showSnackbar']),
        saveContact(){
            this.$refs.form.validate();

            var value = false; 
            for(var key in this.$refs.form._data.errorBag) {
                value = this.$refs.form._data.errorBag[key];
                if(value)
                    break;
            }

            if(value){
                console.log(this.$refs.form._data.errorBag);
            }else{
                //save
                //console.log(this.localForm);
                this.addContact(this.localForm).then(()=>{
                  //console.log(res);
                  this.showSnackbar({
                        showing:true,
                        message: 'Contact updated successfully.',
                        color:'success'
                    });  
                    this.$emit('editedContact');
                }).catch((err)=>{
                    console.log(err);
                    if(err.data.errors)
                        this.showSnackbar({
                            showing:true,
                            message: err.data.errors[0],
                            color:'error'
                        });
                    else
                        this.showSnackbar({
                            showing:true,
                            message: 'Error saving contact.',
                            color:'error'
                        });
                });
            }
        },
        loadCountries(){
            this.getCountries().then(res=>{
            this.countries = res.data.data;
            //console.log(this.countries)
        });
        },
        countryChanged(itm){
            //console.log(itm);
            this.getStates(itm).then(res=>{
                this.states = res.data.data;
                this.stateDisabled = false;
            });
        },
        stateChanged(itm){
            //console.log(itm);
            this.getCities(itm).then(res=>{
                this.cities = res.data.data;
                this.cityDisabled = false;
            });
        }
    }
}
</script>

<style>

</style>